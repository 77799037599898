(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  "mobile": {
    "landscape": 812,
    "portrait": 700,
    "small": 480
  },
  "tablet": {
    "landscape": 1024,
    "portrait": 768
  },
  "notebook": {
    "small": 1280,
    "normal": 1500
  }
};

},{}],2:[function(require,module,exports){
'use strict';

require('./modules/modals.js');

require('./modules/tooltips.js');

require('./modules/forms.js');

require('./modules/masks.js');

require('./modules/spinner.js');

require('./modules/scroll.js');

require('./modules/tabs.js');

require('./modules/galleries.js');

require('./modules/lazyload.js');

require('./modules/spoilers.js');

require('./modules/menus.js');

require('./modules/sliders.js');

require('./modules/scroller-dots.js');

require('./modules/history.js');

require('./modules/datepicker.js');

require('./modules/preheader.js');

require('./modules/vuejs/card/card-app.js');

require('./modules/vuejs/order/order-app.js');

require('./modules/vuejs/profile/profile-app.js');

require('./modules/vuejs/user/user-app.js');

},{"./modules/datepicker.js":3,"./modules/forms.js":4,"./modules/galleries.js":5,"./modules/history.js":8,"./modules/lazyload.js":9,"./modules/masks.js":10,"./modules/menus.js":11,"./modules/modals.js":12,"./modules/preheader.js":13,"./modules/scroll.js":14,"./modules/scroller-dots.js":15,"./modules/sliders.js":16,"./modules/spinner.js":17,"./modules/spoilers.js":18,"./modules/tabs.js":19,"./modules/tooltips.js":20,"./modules/vuejs/card/card-app.js":21,"./modules/vuejs/order/order-app.js":34,"./modules/vuejs/profile/profile-app.js":35,"./modules/vuejs/user/user-app.js":37}],3:[function(require,module,exports){
'use strict';

$(function () {
    $('.js-novue-datepicker').each(function () {
        // var now = new Date();
        // var MyDateString;

        // now.setDate(now.getDate() + 2);
        // MyDateString = ('0' + now.getDate()).slice(-2) + '.' + ('0' + (now.getMonth()+1)).slice(-2) + '.' + now.getFullYear();

        var $input = $(this);

        $input.datepicker({
            minDate: 0
        });

        $input.prop("placeholder", 'Укажите дату');
    });
});

},{}],4:[function(require,module,exports){
'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _globals = require('./globals.js');

// Selectric
// ---------

_globals.$DOCUMENT.on('initSelectric yiiListViewUpdated', function () {
  $('select:not(.no-default-selectric):not(.no-default-selectric-core-component)').each(function () {
    $(this).selectric({
      disableOnMobile: false,
      nativeOnMobile: true,
      inheritOriginalWidth: $(this).hasClass('default-width')
    });
  });
}).trigger('initSelectric');

_globals.$DOCUMENT.htmlEncode = function (str) {
  return String(str).replace(/[^\w. ]/gi, function (c) {
    return '&#' + c.charCodeAt(0) + ';';
  });
};

// Checkboxes
// ----------

_globals.$BODY.on('change initCheckboxes', '.js-checkbox input', function (event) {
  var $inp = $(event.target);
  var $label = $inp.closest('.js-checkbox');

  if ($inp.prop('checked')) {
    $label.addClass('is-checked');
  } else {
    $label.removeClass('is-checked');
  }
});
$('.js-checkbox input').trigger('initCheckboxes');

// Radio buttons
// -------------

_globals.$BODY.on('change initRadio', '.js-radio:not(.no-default-radio) input', function (event) {
  var $inp = $(event.target);
  var $group = $('[name="' + $inp.attr('name') + '"]');
  var $labels = $group.closest('.js-radio');
  var $selected_item = $labels.find('input').filter(':checked').closest('.js-radio');

  $labels.removeClass('is-checked');
  $selected_item.addClass('is-checked');
});
$('.js-radio input').trigger('initRadio');

// Rating stars
// -------------

$('.js-rating-stars .rating__star-item').on('mouseenter', function (e) {
  var $this = $(this);

  $this.prevUntil().addClass('star--full');
  $this.addClass('star--full');
});

$('.js-rating-stars .rating__star-item').on('mouseleave', function (e) {
  var $this = $(this);

  $this.prevUntil().removeClass('star--full');
  $this.removeClass('star--full');
});

$('.js-rating-stars .rating__star-item').on('click', function (e) {
  e.preventDefault();
  var $this = $(this);

  $this.siblings().removeClass('star--fixed');
  $this.removeClass('star--fixed');

  $this.prevUntil().addClass('star--fixed');
  $this.addClass('star--fixed');

  $this.parent().next().val($this.index() + 1);
});

// Textarea autosize
// -----------------

$(function () {
  autosize($('textarea'));
});

// Fileinputs
// ----------

_globals.$BODY.on('change', '.js-fileinput input', function (event) {
  $(event.target).closest('.js-fileinput').parent().find('.js-fileinput-filename').text($(this).val());
});

// Enable inputs
// -------------

_globals.$BODY.on('click', '.js-enable-input', function (e) {
  e.preventDefault();

  var $this = $(this);

  if ($this.hasClass('save-clicked')) {
    $this.removeClass('save-clicked');
    return;
  }

  var $parent = $this.parent();
  var $input = $parent.find('input').length ? $parent.find('input') : $parent.find('textarea');

  $('.errorMessage').hide();
  $('.js-enable-input').removeClass('form__save-button');
  $(this).addClass('form__save-button');

  $input.prop('disabled', false).focus().on('blur.input-edit', function (e) {
    var _this = this;

    setTimeout(function () {
      $(_this).prop('disabled', true).off('blur.input-edit');
      $('.js-enable-input').removeClass('form__save-button');
      $('.js-submit-button').click();
    }, 350);
  });
});

_globals.$BODY.on('click', '.form__save-button', function (e) {
  $(this).addClass('save-clicked');
});

_globals.$BODY.on('click', '.js-enable-all-inputs', function (e) {
  e.preventDefault();
  $(this).closest('form').find('input[disabled], .textarea[disabled]').prop('disabled', false);
  $(this).remove();
});

_globals.$BODY.on('click', '.js-remove-input', function (e) {
  e.preventDefault();
  var $this = $(this);
  var $container = $this.closest('.form__field-group').length ? $this.closest('.form__field-group') : $this.closest('.form__row');
  var $formButt = $this.closest('form').find("button[name='submit']");

  alertify.confirm('Вы уверены ?', function (answer) {
    if (answer) {
      $container.remove();
      $formButt.trigger('click');
    }
  });
});

var OVERLAY_LOADER_DEFAULTS = {
  hideSelector: '.js-close-popup',
  fixedElements: '.js-fixed-element'
};

$(_globals.$BODY).on('click', '.js-form-add-address', function (e) {
  e.preventDefault();
  $('.js-address-popup-caption').text('Новый адрес');
  $('.js-new-address-submit').text('Добавить');
  var $trigger = $(this);
  var $node = $('#new-address-popup');
  var $addressLabel = $('.js-new-address-label');
  var $addressValue = $('.js-new-address-value');
  var $submitBtn = $('.js-new-address-submit');
  $submitBtn.data('action', 'add');

  $addressLabel.val('');
  $addressValue.val('');
  $node.data('trigger', $trigger);
  $.overlayLoader(true, _extends({
    node: $node
  }, OVERLAY_LOADER_DEFAULTS));
});

$(_globals.$BODY).on('click', '.js-edit-address', function (e) {
  e.preventDefault();

  var $trigger = $(this);

  var index = $trigger.data('index');
  var data = window.initProfileApp.$children[0].$data['addressList'][index];

  $('.js-address-popup-caption').text('Редактирование');
  $('.js-new-address-submit').text('Cохранить');
  $('.js-new-address-submit').data('index', index);
  var $node = $('#new-address-popup');
  var $addressLabel = $('.js-new-address-label');
  var $addressValue = $('.js-new-address-value');
  var $submitBtn = $('.js-new-address-submit');
  $submitBtn.data('action', 'edit');

  $addressLabel.val(data.name);
  $addressValue.val(data.address);
  $node.data('trigger', $trigger);
  $.overlayLoader(true, _extends({
    node: $node
  }, OVERLAY_LOADER_DEFAULTS));
});

window.refreshAddressList = function (result) {
  if (result.status != 'ok') return;
  window.initProfileApp.$children[0].$data['addressList'] = result.addressList;
};

window.refreshOrganizationList = function (result) {
  if (result.status != 'ok') return;
  window.initProfileApp.$children[1].$data['organizationList'] = result.organizationList;
};

_globals.$BODY.on('click', '.js-remove-address', function (e) {
  e.preventDefault();
  var self = $(this);
  alertify.confirm('Вы уверены ?', function (answer) {
    if (answer) {
      $.mouseLoader(true);
      $.post('/user/data/addresses/', { action: 'remove', data: { index: self.data('index') } }, function (result) {
        $.mouseLoader(false);
        window.refreshAddressList(result);
      });
    }
  });
});

$(_globals.$BODY).on('click', '.js-new-address-submit', function (e) {
  e.preventDefault();
  var $trigger = $(this);
  var $addressLabel = $('.js-new-address-label');
  var $addressValue = $('.js-new-address-value');

  if ($addressLabel.val() == '') {
    alertify.alert('Поле "Название" не должно быть пустым!');
    return;
  }
  if ($addressValue.val() == '') {
    alertify.alert('Поле "Адрес" не должно быть пустым!');
    return;
  }

  $.mouseLoader(true);
  var data = { name: $addressLabel.val(), address: $addressValue.val(), index: $trigger.data('index') };
  $.post('/user/data/addresses/', { action: $trigger.data('action'), data: data }, function (result) {
    $.mouseLoader(false);
    window.refreshAddressList(result);
    $.overlayLoader(false);
  });
});

_globals.$BODY.on('change', '.js-toggle-requisites-company', function () {
  var $this = $(this);
  var id = $this.val();
  var $group = $('.form__requisites[data-id="' + id + '"]');
  $('.js-toggle-requisites-company').val($this.val()).selectric('refresh');
  $group.show().siblings('.form__requisites').hide();
  $group.find('.form__textarea').each(function () {
    var event = new Event('input', {
      bubbles: true,
      cancelable: true
    });
    $(this)[0].dispatchEvent(event);
  });
});

$(_globals.$BODY).on('click', '.js-form-add-organisation', function (e) {
  e.preventDefault();
  var $trigger = $(this);
  var $node = $('#new-organisation-popup');
  $node.find('.new-entry-popup__caption').text('Новая организация');
  $node.find('.js-new-organisation-submit').text('Добавить');
  $('.js-new-organisation-submit').data('action', 'add');
  $node.find('.form__input, .form__textarea').val('');
  $node.data('trigger', $trigger);
  $.overlayLoader(true, _extends({
    node: $node
  }, OVERLAY_LOADER_DEFAULTS));
});

$(_globals.$BODY).on('click', '.js-form-edit-organisation', function (e) {
  e.preventDefault();
  var $trigger = $(this);
  var $node = $('#new-organisation-popup');
  $node.find('.new-entry-popup__caption').text('Редактирование');
  $node.find('.js-new-organisation-submit').text('Сохранить');
  $('.js-new-organisation-submit').data('action', 'edit');
  $('.js-new-organisation-submit').data('index', $trigger.data('index'));

  var data = window.initProfileApp.$children[1].$data['organizationList'][$trigger.data('index')];

  $node.find('input,textarea').each(function () {
    var attr = $(this).attr('name');
    $(this).val(data[attr]);
  });

  //$node.find('.form__input, .form__textarea').val('');
  $node.data('trigger', $trigger);
  $.overlayLoader(true, _extends({
    node: $node
  }, OVERLAY_LOADER_DEFAULTS));
});

$(_globals.$BODY).on('click', '.js-new-organisation-submit', function (e) {
  var $node = $('#new-organisation-popup');
  var $trigger = $(this);
  if ($node.find('input[name=name]').val() == '') {
    alertify.alert('Поле "Название организации" не должно быть пустым!');
    return;
  }
  $.mouseLoader(true);
  var data = {};
  $node.find('input,textarea').each(function () {
    var attr = $(this).attr('name');
    data[attr] = $(this).val();
  });

  $.post('/user/data/organizations/', { action: $trigger.data('action'), data: data }, function (result) {
    $.mouseLoader(false);
    window.refreshOrganizationList(result);
    $.overlayLoader(false, {
      node: $node
    });
  });
});

_globals.$BODY.on('click', '.js-form-remove-organisation', function (e) {
  e.preventDefault();
  var self = $(this);
  alertify.confirm('Вы уверены ?', function (answer) {
    if (answer) {
      $.mouseLoader(true);
      $.post('/user/data/organizations/', { action: 'remove', data: { index: self.data('index') } }, function (result) {
        $.mouseLoader(false);
        window.refreshOrganizationList(result);
      });
    }
  });
});

$('.js-clear-organisations').click(function (e) {
  e.preventDefault();
  $.overlayLoader(true, _extends({
    node: $('#clear-requisites-popup')
  }, OVERLAY_LOADER_DEFAULTS));
});

$('.js-clear-organisations-confirm').click(function (e) {
  e.preventDefault();
  $('.form__requisites').remove();
  $.overlayLoader(false);
});

// Переход по урл заданному у option select'а
_globals.$BODY.on('change', '.js-select-url', function (e) {
  if ($(this).val() != '') window.location.href = $(this).val();
});

// Устанавливаем выбраенные по умолчанию option
$(function () {
  if ($('.js-select-url').length) {
    $('.js-select-url').val($('.js-select-url option.active').attr('value'));
    $('.js-select-url').selectric('refresh');
  }
});

// Filters

(function () {

  if (!$('.js-toggle-mobile-filters').length) return;

  var opened_class = 'filter-is-opened';

  var url = new URL(window.location);
  if (url.searchParams.get('filter')) {
    _globals.$BODY.addClass(opened_class);
  }

  $(document).on('click', '.js-toggle-mobile-filters', function (e) {
    e.preventDefault();

    _globals.$BODY.toggleClass(opened_class);

    if (_globals.$BODY.hasClass(opened_class)) {
      var _url = new URL(window.location);
      _url.searchParams.set('filter', 'opened');
      window.history.pushState({}, '', _url);
    } else {
      var _url2 = new URL(window.location);
      if (_url2.searchParams.get('filter')) {
        window.history.back();
      }
    }
  });

  $(document).on('click.close-mobile-filters', function (e) {
    var $target = $(e.target);
    if ($target.closest('.filter').length || $target.hasClass('js-toggle-mobile-filters')) return;
    if (_globals.$BODY.hasClass(opened_class)) {
      $('.js-toggle-mobile-filters').first().click();
    }
  });

  window.addEventListener('popstate', onPopstate);

  function onPopstate(event) {
    var url = new URL(window.location);
    if (!url.searchParams.get('filter')) {
      _globals.$BODY.removeClass(opened_class);
    } else {
      _globals.$BODY.addClass(opened_class);
    }
  };
})();

// date inputs

// $('body').on('change', '.date-input', function() {
//   if (this.value) {
//     $(this).siblings('.date-input-value').text(
//       moment(this.value, 'YYYY-MM-DD').format(this.getAttribute('data-date-format'))
//     );
//   }
// });

// $('.js-date-input').each(function() {
//   const $input = $(this);
//   $input.datepicker({
//     onSelect(dateText, inst) {
//       // date = dateToYMD(new Date(inst.selectedYear, inst.selectedMonth, inst.selectedDay));
//     }
//   });
// });

// function dateToYMD(date) {
//   let d = date.getDate();
//   let m = date.getMonth() + 1;
//   let y = date.getFullYear();
//   return '' + y + '-' + (m<=9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
// }

},{"./globals.js":6}],5:[function(require,module,exports){
'use strict';

$().fancybox({
  selector: '.js-zoom'
});

},{}],6:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TOSRUS_DEFAULTS = exports.$BODY = exports.$HTML = exports.$DOCUMENT = exports.$WINDOW = exports.HEADER_HEIGHT = exports.NOTEBOOK_WIDTH = exports.SMALL_NOTEBOOK_WIDTH = exports.TABLET_WIDTH = exports.PORTRAIT_TABLET_WIDTH = exports.LANDSCAPE_MOBILE_WIDTH = exports.MOBILE_WIDTH = exports.SMALL_MOBILE_WIDTH = exports.IS_TOUCH_DEVICE = exports.IS_IE = exports.IS_IOS = exports.IS_DESKTOP = exports.IS_MOBILE = undefined;

var _mediaQueriesConfigJson = require('../../media-queries-config.json.js');

var _mediaQueriesConfigJson2 = _interopRequireDefault(_mediaQueriesConfigJson);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// Const
// -----

var IS_MOBILE = exports.IS_MOBILE = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i) !== null;
var IS_DESKTOP = exports.IS_DESKTOP = !IS_MOBILE;
var IS_IOS = exports.IS_IOS = navigator.userAgent.match(/(iPad|iPhone|iPod)/g) ? true : false;
var IS_IE = exports.IS_IE = navigator.appVersion.indexOf("MSIE") !== -1 || navigator.userAgent.match(/Trident.*rv[ :]*11\./);
var IS_TOUCH_DEVICE = exports.IS_TOUCH_DEVICE = 'ontouchstart' in document;

var HTML = document.documentElement;

HTML.classList.remove('no-js');

if (IS_MOBILE) HTML.classList.add('is-mobile');
if (IS_DESKTOP) HTML.classList.add('is-desktop');
if (IS_IOS) HTML.classList.add('is-ios');
if (IS_IE) HTML.classList.add('is-ie');
if (IS_TOUCH_DEVICE) HTML.classList.add('is-touch-device');

var SMALL_MOBILE_WIDTH = exports.SMALL_MOBILE_WIDTH = _mediaQueriesConfigJson2.default.mobile.small;
var MOBILE_WIDTH = exports.MOBILE_WIDTH = _mediaQueriesConfigJson2.default.mobile.portrait;
var LANDSCAPE_MOBILE_WIDTH = exports.LANDSCAPE_MOBILE_WIDTH = _mediaQueriesConfigJson2.default.mobile.landscape;
var PORTRAIT_TABLET_WIDTH = exports.PORTRAIT_TABLET_WIDTH = _mediaQueriesConfigJson2.default.tablet.portrait;
var TABLET_WIDTH = exports.TABLET_WIDTH = _mediaQueriesConfigJson2.default.tablet.landscape;
var SMALL_NOTEBOOK_WIDTH = exports.SMALL_NOTEBOOK_WIDTH = _mediaQueriesConfigJson2.default.notebook.small;
var NOTEBOOK_WIDTH = exports.NOTEBOOK_WIDTH = _mediaQueriesConfigJson2.default.notebook.normal;

var HEADER_HEIGHT = exports.HEADER_HEIGHT = $('.header').height();

// Selectors
// ---------

var $WINDOW = exports.$WINDOW = $(window);
var $DOCUMENT = exports.$DOCUMENT = $(document);
var $HTML = exports.$HTML = $(document.documentElement);
var $BODY = exports.$BODY = $(document.body);

// Tosrus default settings
// -----------------------

var TOSRUS_DEFAULTS = exports.TOSRUS_DEFAULTS = {
  buttons: {
    next: true,
    prev: true
  },
  keys: {
    prev: 37,
    next: 39,
    close: 27
  },
  wrapper: {
    onClick: 'close'
  }
};

// Custom vh for mobile devices
// ----------------------------
// css example:
// height: calc(var(--vh, 1vh) * 100);

$(window).on('resize initVh', function () {
  var vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', vh + 'px');
}).trigger('initVh');

},{"../../media-queries-config.json.js":1}],7:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IS_SMALL_MOBILE_WIDTH = exports.IS_LANDSCAPE_MOBILE_WIDTH = exports.IS_MOBILE_WIDTH = exports.IS_TABLET_WIDTH = exports.IS_SMALL_NOTEBOOK_WIDTH = exports.IS_NOTEBOOK_WIDTH = exports.IS_DESKTOP_WIDTH = exports.WINDOW_HEIGHT = exports.WINDOW_WIDTH = undefined;
exports.status = status;

var _globals = require('./globals.js');

var WINDOW_WIDTH = exports.WINDOW_WIDTH = window.innerWidth || _globals.$WINDOW.width();
var WINDOW_HEIGHT = exports.WINDOW_HEIGHT = _globals.$WINDOW.height();
_globals.$WINDOW.resize(function () {
  exports.WINDOW_WIDTH = WINDOW_WIDTH = window.innerWidth || _globals.$WINDOW.width();
  exports.WINDOW_HEIGHT = WINDOW_HEIGHT = _globals.$WINDOW.height();
});

var IS_DESKTOP_WIDTH = exports.IS_DESKTOP_WIDTH = function IS_DESKTOP_WIDTH() {
  return WINDOW_WIDTH > _globals.NOTEBOOK_WIDTH;
};
var IS_NOTEBOOK_WIDTH = exports.IS_NOTEBOOK_WIDTH = function IS_NOTEBOOK_WIDTH() {
  return WINDOW_WIDTH > _globals.SMALL_NOTEBOOK_WIDTH && WINDOW_WIDTH <= _globals.NOTEBOOK_WIDTH;
};
var IS_SMALL_NOTEBOOK_WIDTH = exports.IS_SMALL_NOTEBOOK_WIDTH = function IS_SMALL_NOTEBOOK_WIDTH() {
  return WINDOW_WIDTH > _globals.TABLET_WIDTH && WINDOW_WIDTH <= _globals.SMALL_NOTEBOOK_WIDTH;
};
var IS_TABLET_WIDTH = exports.IS_TABLET_WIDTH = function IS_TABLET_WIDTH() {
  return WINDOW_WIDTH >= _globals.PORTRAIT_TABLET_WIDTH && WINDOW_WIDTH <= _globals.TABLET_WIDTH;
};
var IS_MOBILE_WIDTH = exports.IS_MOBILE_WIDTH = function IS_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.MOBILE_WIDTH;
};
var IS_LANDSCAPE_MOBILE_WIDTH = exports.IS_LANDSCAPE_MOBILE_WIDTH = function IS_LANDSCAPE_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.LANDSCAPE_MOBILE_WIDTH;
};
var IS_SMALL_MOBILE_WIDTH = exports.IS_SMALL_MOBILE_WIDTH = function IS_SMALL_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.SMALL_MOBILE_WIDTH;
};

// Response status
function status(response) {
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response;
}

},{"./globals.js":6}],8:[function(require,module,exports){
'use strict';

var $backLink = $('.js-history-back');
var currentUrl = window.location.href;

if ($backLink.length) {
  if (document.referrer == '') {
    $backLink.css('visibility', 'hidden');
  } else {
    $backLink.click(function () {
      window.history.back();
      setTimeout(function () {
        // if location was not changed in 100 ms, then there is no history back
        if (currentUrl === window.location.href) {
          window.close();
        }
      }, 100);
    });
  }
}

},{}],9:[function(require,module,exports){
'use strict';

(function () {

  var lazyloadImages = document.querySelectorAll('.js-lazy-pic');
  var imageIntersectionObserver = void 0;
  var completed = false;

  document.addEventListener('DOMContentLoaded', function () {
    initLazyLoad();
    addImagesListUpdateHandlers();
  });

  function initLazyLoad() {
    if (supportsIntersectionObserver()) return observeImages();
    observerFallback();
  }

  function supportsIntersectionObserver() {
    return 'IntersectionObserver' in window;
  }

  function observeImages() {
    createObserver();
    lazyloadImages.forEach(function (image) {
      imageIntersectionObserver.observe(image);
    });
  }

  function createObserver() {
    imageIntersectionObserver = new IntersectionObserver(function (entries, observer) {
      processImages(entries);
    }, {
      rootMargin: '200px'
    });
  }

  function processImages(images) {
    images.forEach(function (item) {
      if (item.isIntersecting) {
        changeSrc(item.target);
        imageIntersectionObserver.unobserve(item.target);
      }
    });
  }

  function changeSrc(image) {
    image.src = image.dataset.src;
    image.classList.remove('js-lazy-pic');
  }

  function observerFallback() {
    var lazyloadThrottleTimeout = void 0;

    document.addEventListener('scroll', lazyload);
    window.addEventListener('resize', lazyload);
    window.addEventListener('orientationChange', lazyload);
  }

  function lazyload() {
    lazyloadImages = document.querySelectorAll('.js-lazy-pic');

    if (lazyloadThrottleTimeout) {
      clearTimeout(lazyloadThrottleTimeout);
    }

    lazyloadThrottleTimeout = setTimeout(function () {
      lazyloadImages.forEach(function (image) {
        if (imageIsReached(image)) {
          changeSrc(image);
        }
      });
      if (noMoreImagesLeft()) {
        removeLazyLoadListeners();
        completed = true;
      }
    }, 20);
  }

  function imageIsReached(image) {
    var scrollTop = window.pageYOffset;
    return image.offsetTop < window.innerHeight + scrollTop;
  }

  function noMoreImagesLeft() {
    return lazyloadImages.length == 0;
  }

  function addImagesListUpdateHandlers() {
    $(document).on('yiiListViewUpdated', function () {
      addImagesToObserver();
    });
    $('body').on('iasRender', function () {
      addImagesToObserver();
    });
  }

  function addImagesToObserver() {
    lazyloadImages = document.querySelectorAll('.js-lazy-pic');
    if (supportsIntersectionObserver()) {
      lazyloadImages.forEach(function (image) {
        imageIntersectionObserver.observe(image);
      });
    } else {
      if (completed) {
        observerFallback();
      }
    }
  }

  function removeLazyLoadListeners() {
    document.removeEventListener('scroll', lazyload);
    window.removeEventListener('resize', lazyload);
    window.removeEventListener('orientationChange', lazyload);
  }
})();

},{}],10:[function(require,module,exports){
'use strict';

(function () {

  function initializePhoneMasks() {
    $('input[type="tel"]').each(function () {
      var $input = $(this);
      var defaultPlaceholder = $input.attr('placeholder') || '';
      var defaultPlaceholderMask = '+7 (___) ___-__-__';
      var phoneMask = '+7 (000) 000-00-00';
      var pasted = false;

      $input.on('focus', function () {
        $input.attr('placeholder', defaultPlaceholderMask);
      }).on('blur', function () {
        $input.attr('placeholder', defaultPlaceholder);
      });

      $input.on('paste drop', function () {
        var isEmpty = !$input.val();
        var element = $input.get(0);
        var selectionLength = element.selectionEnd - element.selectionStart;

        if (!isEmpty && $input.val().length !== selectionLength) {
          return;
        }

        pasted = true;
      });

      $input.on('input', function (e) {
        var value = $input.val();

        if (value.startsWith('8')) {
          if (pasted && value.length !== 11) {
            pasted = false;
            return;
          }

          pasted = false;

          e.stopPropagation();

          value = value.replace('8', '+7');
          $input.val(value);
        }
      });

      $input.mask(phoneMask);
    });
  }

  $(window).on('initializePhoneMasks', initializePhoneMasks).trigger('initializePhoneMasks');
})();

},{}],11:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

var $menus = $('.js-menu');

if (!_globals.IS_TOUCH_DEVICE) {
  $menus.on('mouseenter.js-menu', '.menu-list__item', function () {
    var $this = $(this);
    var $submenu = $('' + $this.data("dropdown"));

    clearTimeout($this.data('unhoverTimeout'));

    if ($this.hasClass('has-dropdown')) {
      $this.data('hoverTimeout', $this.addClass('is-hovered'));
      var _$submenu = $('' + $this.data("dropdown"));

      _$submenu.siblings().hide();
      _$submenu.fadeIn(80);
    } else {
      $this.addClass('is-hovered');

      $('.dropdown > ul').hide();
    }
  });

  $menus.on('mouseleave.js-menu', '.menu-list__item', function () {
    var $this = $(this);

    clearTimeout($this.data('hoverTimeout'));

    $this.data('unhoverTimeout', $this.removeClass('is-hovered'));
  });
}

if (_globals.IS_TOUCH_DEVICE) {
  $menus.on('click.js-menu', 'a', function (e) {
    e.preventDefault();

    var $this = $(this);
    var $parent = $this.parent();
    var hasDropdown = $parent.hasClass('has-dropdown');
    var isBackButton = $this.hasClass('js-close-dropdown');
    var isSpoiler = $this.hasClass('js-toggle-mobile-spoiler');
    var isHovered = $parent.hasClass('is-hovered');
    var link = $this.attr('href');

    if (hasDropdown) {
      if (isHovered && link) {
        location.href = link;
      } else {
        $parent.siblings().removeClass('is-hovered');
        $parent.closest('.menu-list__group').siblings().find('.menu-list__item.has-dropdown').removeClass('is-hovered');
        $parent.addClass('is-hovered');

        var $submenu = $('' + $parent.data("dropdown"));

        $submenu.siblings('ul').hide();
        $submenu.fadeIn(80);
        $submenu.closest('.dropdown').addClass('is-active');
      }
    } else {

      if (isBackButton) {
        $('.dropdown').removeClass('is-active');
        $('.dropdown > ul').fadeOut();
        $('.has-dropdown.is-hovered').removeClass('is-hovered');
        return;
      }

      if (isSpoiler) {
        return;
      }

      if (link) {
        location.href = link;
      }
    }
  });
}

// $('.js-toggle-dropdown').click(function() {
//   $(this).closest('.has-dropdown').toggleClass('is-hovered');
// });

(function () {

  if (!$('.popup-menu').length) return;

  var menuScrollbar = new PerfectScrollbar('.js-menu-scrollbar');
  var dropdownScrollbar = new PerfectScrollbar('.js-dropdown-scrollbar');
  // const $menuScrollbar = $('.js-menu-scrollbar');

  // $('.js-dropdown-scrollbar, .js-menu-scrollbar')[0].addEventListener('ps-scroll-y', (e, at) => {
  //   if ($menuScrollbar.scrollTop() > 0) {
  //     $menuScrollbar.addClass('scrolled');
  //   } else {
  //     $menuScrollbar.removeClass('scrolled');
  //   }
  // });

  var opened_class = 'menu-is-opened';

  $('.js-toggle-popup-menu').click(function (e) {
    e.preventDefault();

    _globals.$BODY.toggleClass(opened_class);

    $('.popup-menu .is-hovered').removeClass('is-hovered');

    if (_globals.$BODY.hasClass(opened_class)) {
      var url = new URL(window.location);
      url.searchParams.set('menu', 'opened');
      window.history.pushState({}, '', url);
      // menuScrollbar.update();

      _globals.$BODY.on('click.close-menu', function (e) {
        var $target = $(e.target);

        if ($target.closest('.popup-menu').length || $target.hasClass('js-toggle-popup-menu')) {
          return;
        }

        closeMenu(true);
      });
    } else {
      closeMenu(true);
    }
  });

  function closeMenu(back) {
    _globals.$BODY.removeClass(opened_class).off('click.close-menu');

    var url = new URL(window.location);
    if (url.searchParams.get('menu') && back) {
      window.history.back();
    }
  }

  window.addEventListener('popstate', onPopstate);

  function onPopstate(event) {
    if (_globals.$BODY.hasClass(opened_class)) {
      closeMenu(false);
    }
  };

  $('.js-toggle-mobile-menu-dropdown').click(function () {
    $(this).closest('ul').toggleClass('is-expanded');
  });

  // const hammerobject = new Hammer($('.popup-menu')[0], {});
  // hammerobject.on('swipeleft', function(e) {
  //   if ($BODY.hasClass(opened_class)) {
  //     closeMenu(true);
  //   }
  // });

  $('.header__catalogue-menu-content').click(function (e) {
    if (e.target == this) {
      closeMenu(true);
    }
  });
})();

},{"./globals.js":6}],12:[function(require,module,exports){
'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _globals = require('./globals.js');

var OVERLAY_LOADER_DEFAULTS = {
  hideSelector: '.js-close-popup',
  fixedElements: '.js-fixed-element'
};

_globals.$DOCUMENT.on('click.overlay-open', '.js-overlay', function (e) {
  e.preventDefault();

  var $popup = $(this).attr('href');

  $.overlayLoader(true, _extends({
    node: $popup
  }, OVERLAY_LOADER_DEFAULTS));
});

_globals.$DOCUMENT.on('click.overlay-open', '.js-ajax-overlay', function (e) {
  e.preventDefault();

  var $self = $(this);
  var $node = $($self.attr('href'));

  $.overlayLoader(true, _extends({
    node: $node
  }, OVERLAY_LOADER_DEFAULTS));

  $.ajax({
    type: $self.data('type'),
    url: $self.data('ajax-url'),
    data: _parseAjaxOverlayParams($self.data('ajax-params')),
    timeout: 10000,
    success: function success(data) {
      $node.find('.js-ajax-data').replaceWith(data);
      if ($node.find('.js-card-app').length) {
        initCardApp('#' + $node.find('.js-card-app').attr('id'));
      }
      setTimeout(function () {
        $(document).trigger('ajaxPopupLoaded');
      }, 350);
    },
    error: function error(data) {}
  });
});

function _parseAjaxOverlayParams(str) {

  if (str == undefined || str == '') return {};

  if ((typeof str === 'undefined' ? 'undefined' : _typeof(str)) === 'object') return str;

  var jsonParams = JSON.parse(str);
  if (jsonParams) return jsonParams;

  var obj = {};
  var array = str.split('&');

  array.forEach(function (value) {
    var _a = value.split('=');
    obj[_a[0]] = _a[1];
  });

  return obj;
}

// Autofocus
_globals.$DOCUMENT.on('overlayLoaderShow', function (e, $node) {
  $node.find('.js-autofocus-inp').focus();
});

$(function () {

  // document.querySelector('.js-zoom-on-hover').addEventListener('mousemove', (e) => {
  //   console.log('zoooooom');
  //   var zoomer = e.currentTarget;
  //   let offsetX, offsetY, x, y;
  //   e.offsetX ? offsetX = e.offsetX : offsetX = e.touches[0].pageX
  //   e.offsetY ? offsetY = e.offsetY : offsetX = e.touches[0].pageX
  //   x = offsetX/zoomer.offsetWidth*100
  //   y = offsetY/zoomer.offsetHeight*100
  //   zoomer.style.backgroundPosition = x + '% ' + y + '%';
  // });

  $('.js-play-video').click(function (e) {
    e.preventDefault();
    var $this = $(this);
    var $videoLink = $this.data('video');
    var $video = $('<iframe width="100%" height="100%" src="' + $videoLink + '" frameborder="0" allowfullscreen></iframe>');
    var $popup = $(this).attr('href');

    // $popup.append($video);
    $video.appendTo($popup);

    $.overlayLoader(true, _extends({
      node: $popup
    }, OVERLAY_LOADER_DEFAULTS));
  });
});

},{"./globals.js":6}],13:[function(require,module,exports){
'use strict';

var _closableTeaser = require('./widgets/closable-teaser.js');

var _closableTeaser2 = _interopRequireDefault(_closableTeaser);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var teaser = new _closableTeaser2.default({
  selector: '.js-preheader-notice',
  closeSelector: '.js-close-preheader-notice',
  storageKey: 'PREHEADER_HIDE_DATE',
  daysToHide: 1
});

},{"./widgets/closable-teaser.js":38}],14:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

var _helpers = require('./helpers.js');

// Scroll to
// ---------

_globals.$DOCUMENT.on('click.scroll-to', '.js-scroll-to', function (e) {
  e.preventDefault();

  var $link = $(this);
  var $elementToScroll = $($link.attr('href'));
  var speed = $link.data('speed') || 150;
  var offset = $link.data('offset') || -$('.header').height() - 30;

  _globals.$WINDOW.scrollTo($elementToScroll, { duration: speed, offset: offset });
});
'';

// Scrolling to top
// ----------------

if (!(0, _helpers.IS_MOBILE_WIDTH)()) {
  var $goTopButton = $('<div class="go-top-btn"></div>');

  $goTopButton.click(function () {
    _globals.$WINDOW.scrollTo(0, 200);
  });

  _globals.$WINDOW.scroll(function () {
    var scrollTop = _globals.$WINDOW.scrollTop();
    if (scrollTop > 0) {
      $goTopButton.addClass('is-visible');
    } else {
      $goTopButton.removeClass('is-visible');
    }
  });

  _globals.$BODY.append($goTopButton);
}

// Scrolled state
// --------------
$(function () {
  var lastScrollPosition = $(window).scrollTop();
  $(window).on('scroll', function () {
    var scrollPosition = $(window).scrollTop();
    if (scrollPosition > 0) {
      $('body').addClass('scrolled');
      if (scrollPosition > $('.header').height()) {
        $('body').addClass('scrolled-from-top');
      } else {
        $('body').removeClass('scrolled-from-top');
      }
      if (scrollPosition > lastScrollPosition) {
        $('body').addClass('scrolled-down').removeClass('scrolled-up');
      }
      if (scrollPosition < lastScrollPosition) {
        $('body').addClass('scrolled-up').removeClass('scrolled-down');
      }
      lastScrollPosition = scrollPosition;
    } else {
      $('body').removeClass('scrolled scrolled-up scrolled-down');
    }
  }).trigger('scroll');
});

// Initial scrollingto hash
// ------------------------

$(function () {
  if (window.location.hash) {
    var offset = -$('.header').height();
    var $blockToScroll = $(window.location.hash);

    if (!$blockToScroll.length) return;

    var position = $blockToScroll.offset().top + offset;

    setTimeout(function () {
      $.scrollTo(position, 0);
    }, 350);
  }
});

},{"./globals.js":6,"./helpers.js":7}],15:[function(require,module,exports){
'use strict';

var _helpers = require('./helpers.js');

var supportsIntersectionObserver = 'IntersectionObserver' in window;

$('.js-scroller-dots').each(function () {

  var $scroller = $(this);
  var containerClass = 'scroller-dots';
  var initialisedClass = 'is-initialised';
  var itemClass = 'scroller-dots__item';
  var activeClass = 'scroller-dots__item--active';
  var $pager = $('<ul class="' + containerClass + '"></ul>');
  var $items = $scroller.find('> *');
  var initialised = false;
  var visibleOnTablets = $scroller.hasClass('tablets-scroller-dots');

  function belowBreakpoint() {
    return visibleOnTablets ? (0, _helpers.IS_TABLET_WIDTH)() || (0, _helpers.IS_MOBILE_WIDTH)() : (0, _helpers.IS_SMALL_MOBILE_WIDTH)();
  }

  $(window).on('resize scrollerDotsInit', function () {
    initScrollerDots();
  }).trigger('scrollerDotsInit');

  function initScrollerDots() {
    if (!belowBreakpoint()) {
      removeDots();
      return initialised = false;
      $scroller.removeClass(initialisedClass);
    }

    if (!initialised) {
      buildScrollerDots();
    }
  }

  function buildScrollerDots() {
    $scroller.after($pager.empty());

    $items.each(function () {
      createDot($(this));
    });

    if (!supportsIntersectionObserver) {
      return intersectionObserverFallback();
    }

    var itemsObserver = createItemsObserver();

    $items.each(function () {
      itemsObserver.observe(this);
    });

    initialised = true;
    $scroller.addClass(initialisedClass);
  }

  function removeDots() {
    $scroller.next('.' + containerClass).remove();
  }

  function createDot($item) {
    var $dot = $('<li class="' + itemClass + '"></li>');
    $pager.append($dot);

    createDotHandler($dot, $item);
  }

  function createDotHandler($dot, $item) {
    $dot.click(function () {
      $scroller.scrollTo($item, 250);
    });
  }

  function createItemsObserver() {
    return new IntersectionObserver(function (entries, observer) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          var index = $(entry.target).index() + 1;
          setActiveItem(index);
        }
      });
    }, {
      threshold: .9
    });
  }

  function setActiveItem(index) {
    if ($pager.find('li:nth-child(' + index + ')').is(':last-child')) {
      $pager.find('li:nth-child(' + index + ')').hide();
    } else {
      $pager.find('li:nth-child(' + index + ')').addClass(activeClass).siblings().removeClass(activeClass);
    }
  }

  function intersectionObserverFallback() {
    var itemObserveThrottleTimeout = void 0;
    setFirstDotActive();
    $scroller[0].addEventListener('scroll', changeDots);
    window.addEventListener('resize', changeDots);
    window.addEventListener('orientationChange', changeDots);
  }

  function setFirstDotActive() {
    $pager.find('li:first-child').addClass(activeClass);
  }

  function changeDots() {
    if (itemObserveThrottleTimeout) {
      clearTimeout(itemObserveThrottleTimeout);
    }
    itemObserveThrottleTimeout = setTimeout(function () {
      var index = findActiveIndex();
      setActiveItem(index);
    }, 50);
  }

  function findActiveIndex() {
    var index = void 0;
    var scrollLeft = $scroller.scrollLeft();
    $items.each(function (i, item) {
      if (scrollLeft < $(item)[0].offsetLeft) {
        index = i;
        return false;
      }
    });
    return index + 1;
  }
});

},{"./helpers.js":7}],16:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

setTimeout(function () {
  $('.js-slideshow').each(function () {
    var $slider = $(this);

    $slider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
      var nextSlideElement = slick.$slides.get(nextSlide);
      var $slideSoureSets = $(nextSlideElement).find('source');
      $($slideSoureSets).each(function () {
        $(this).attr('srcset', $(this).data('lazy'));
      });
    });

    $slider.on('init', function () {
      setTimeout(function () {
        $slider.slick('slickPlay');
      }, 1000);
    });

    $slider.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      autoplay: false,
      autoplaySpeed: 5000,
      dots: true,
      arrows: true,
      pauseOnHover: false,
      lazyLoad: 'progressive',
      responsive: [{
        breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
        settings: {
          arrows: false
        }
      }]
    });
  });
}, 350);

$('.js-catering__gallery').each(function () {
  var $this = $(this);

  $this.slick({
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: true,
    autoplay: false,
    dots: false,
    arrows: true,
    touchThreshold: 100,
    responsive: [{
      breakpoint: _globals.PORTRAIT_TABLET_WIDTH + 1,
      settings: {
        slidesToShow: 1
      }
    }, {
      breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
      settings: {
        slidesToShow: 1,
        arrows: false,
        dots: true
      }
    }]
  });
});

$('.js-letter-group-4').each(function () {
  var $this = $(this);

  $this.slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    autoplay: false,
    dots: true,
    arrows: false,
    touchThreshold: 100,
    responsive: [{
      breakpoint: 1024 + 1,
      settings: {
        slidesToShow: 3
      }
    }, {
      breakpoint: 750 + 1,
      settings: {
        slidesToShow: 2
      }
    }, {
      breakpoint: 375 + 1,
      settings: {
        slidesToShow: 1
      }
    }]
  });
});

$('.js-client-grid').each(function () {
  var $slider = $(this);

  $slider.slick({
    slidesToShow: 6,
    slidesToScroll: 6,
    rows: 3,
    dots: true,
    arrows: false,
    swipeToSlide: true,
    touchThreshold: 100,
    infinite: false,
    responsive: [{
      breakpoint: 1390,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5
      }
    }, {
      breakpoint: _globals.TABLET_WIDTH + 1,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4
      }
    }, {
      breakpoint: _globals.MOBILE_WIDTH + 1,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    }]
  });
});

$('.js-portfolio-slider-catering').each(function () {
  var $this = $(this);

  $this.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: false,
    dots: false,
    arrows: true,
    touchThreshold: 100,
    prevArrow: $('.swiper-button-prev'),
    nextArrow: $('.swiper-button-next')
    // responsive: [
    //   {
    //     breakpoint: 1024 + 1,
    //     settings: {
    //       slidesToShow: 3,
    //     },
    //   },
    //   {
    //     breakpoint: 750 + 1,
    //     settings: {
    //       slidesToShow: 2,
    //     },
    //   },
    //   {
    //     breakpoint: 375 + 1,
    //     settings: {
    //       slidesToShow: 1,
    //     },
    //   }
    // ],
  });
});

$('.js-portfolio-section-slider').each(function () {
  var $this = $(this);

  $this.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: false,
    dots: false,
    arrows: true,
    touchThreshold: 100
  });
});

$('.js-dish-of-the-day-slider').each(function () {
  var $slider = $(this);
  var $status = $slider.next().find('.js-dish-of-the-day-slider-counter');

  $slider.on('init reInit beforeChange', function (event, slick, currentSlide, nextSlide) {
    var i = (nextSlide ? nextSlide : 0) + 1;
    $status.html('<span>' + i + '</span> / ' + slick.slideCount);
    $slider.find('.slick-slide.previous').removeClass('previous');
    $slider.find('.slick-current').addClass('previous');
  });

  $slider.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    dots: false,
    arrows: true,
    prevArrow: $slider.next().find('.js-dish-of-the-day-slider-prev'),
    nextArrow: $slider.next().find('.js-dish-of-the-day-slider-next'),
    responsive: [{
      breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
      settings: {
        arrows: false,
        dots: true
      }
    }]
  });
});

setTimeout(function () {
  $('.js-clients-slider').each(function () {
    var $slider = $(this);
    var $prev = $('.js-clients-prev');
    var $next = $('.js-clients-next');
    // const $pager = $('.js-clients-pager');

    $slider.slick({
      slidesToShow: 6,
      slidesToScroll: 6,
      rows: 2,
      dots: true,
      arrows: true,
      // appendDots: $pager,
      prevArrow: $prev,
      nextArrow: $next,
      responsive: [{
        breakpoint: 1390,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5
        }
      }, {
        breakpoint: _globals.TABLET_WIDTH + 1,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      }, {
        breakpoint: _globals.MOBILE_WIDTH + 1,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      }]
    });
  });
}, 350);

$(function () {
  $('.js-portfolio-slider').each(function () {
    var $slider = $(this);

    $slider.slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      infinite: false,
      responsive: [{
        breakpoint: _globals.LANDSCAPE_MOBILE_WIDTH + 1,
        settings: {
          slidesToShow: 2
        }
      }, {
        breakpoint: _globals.SMALL_MOBILE_WIDTH + 2,
        settings: {
          slidesToShow: 1,
          dots: true,
          arrows: false
        }
      }]
    });
  });
});

$(function () {
  $(window).on('sliders-init resize ajaxPopupLoaded offerGalleryInit', function (e) {
    $('.js-product-gallery').each(function () {
      var $slider = $(this);
      var $connected = $slider.closest('.js-gallery-root').find('.js-product-thumbs');

      if ($slider.hasClass('slick-initialized')) {
        if (e.type == 'offerGalleryInit') {
          $slider.slick('unslick');
        } else {
          return;
        }
      }

      $slider.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        arrows: false,
        dots: false,
        asNavFor: $connected,
        responsive: [{
          breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
          settings: {
            dots: true,
            adaptiveHeight: true
          }
        }]
      });
    });

    $('.js-product-thumbs').each(function () {
      var $slider = $(this);
      var $parent = $slider.parent();
      var $connected = $slider.closest('.js-gallery-root').find('.js-product-gallery');

      if ($slider.hasClass('slick-initialized')) return;

      $slider.slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
        arrows: true,
        dots: false,
        appendArrows: $parent,
        asNavFor: $connected,
        focusOnSelect: true,
        responsive: [{
          breakpoint: _globals.TABLET_WIDTH + 1,
          settings: {
            slidesToShow: 3
          }
        }]
      });
    });
  }).trigger('sliders-init');
});

$(function () {
  $(window).on('resize vitrineSlidersInit', function () {
    $('.js-vitrine-slider').each(function () {
      var $slider = $(this);

      setTimeout(function () {
        if ($slider.hasClass('slick-initialized')) {
          if ($(window).width() <= _globals.SMALL_MOBILE_WIDTH) {
            $slider.slick('unslick');
          } else {
            if ($slider.find('.slick-track').width() == 0) {
              $slider.slick('unslick');
            } else {
              return;
            }
          }
        }

        if ($(window).width() <= 812) return;

        $slider.slick({
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: false,
          arrows: true,
          infinite: false,
          responsive: [{
            breakpoint: 1024 + 1,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          }, {
            breakpoint: 812 + 1,
            settings: 'unslick'
          }]
        });
      }, 300);
    });
  }).trigger('vitrineSlidersInit');
});

$(function () {
  $(window).on('resize vitrineSlidersInit', function () {
    $('.js-homepage-vitrine-slider').each(function () {
      var $slider = $(this);

      setTimeout(function () {
        if ($slider.hasClass('slick-initialized')) {
          if ($(window).width() > _globals.SMALL_MOBILE_WIDTH) {
            $slider.slick('unslick');
          }
          // else {
          //   if ($slider.find('.slick-track').width() == 0) {
          //     $slider.slick('unslick');
          //   } else {
          //     return;
          //   }
          // }
        }

        if ($(window).width() > _globals.SMALL_MOBILE_WIDTH) return;

        $slider.slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: true,
          infinite: false,
          responsive: [{
            breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
            settings: {
              arrows: false
            }
          }]
        });
      }, 300);
    });
  }).trigger('vitrineSlidersInit');
});

},{"./globals.js":6}],17:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

_globals.$DOCUMENT.on('mousedown.js-spinner keypress', '.js-spinner-down, .js-spinner-up', function (e) {
  var $spinner_control = $(this);
  var $input = $spinner_control.siblings('input');
  var step = $input.data('step') ? $input.data('step') : 1;
  var may_be_zero = $input.data('zero') ? $input.data('zero') : false;
  var value = parseInt($input.val(), 10);
  var inc_timeout = void 0,
      inc_interval = void 0,
      dec_timeout = void 0,
      dec_interval = void 0;

  if (e.type == 'keypress' && e.key != 'Enter') return;

  $spinner_control.on('mouseup.js-spinner keyup', clearAll).on('mouseleave.js-spinner keyup', $spinner_control, clearAll);

  if ($spinner_control.hasClass('js-spinner-down')) {
    decVal();
    dec_timeout = setTimeout(function () {
      dec_interval = setInterval(decVal, 70);
    }, 300);
  }

  if ($spinner_control.hasClass('js-spinner-up')) {
    incVal();
    inc_timeout = setTimeout(function () {
      inc_interval = setInterval(incVal, 70);
    }, 300);
  }

  function incVal() {
    if ($.isMouseLoaderActive()) return;

    value = value + step;
    $input.val(value).change();
  }

  function decVal() {
    if ($.isMouseLoaderActive()) return;

    if (may_be_zero) {
      if (value >= step) {
        value = value - step;
        $input.val(value).change();
      }
    } else {
      if (value > step) {
        value = value - step;
        $input.val(value).change();
      }
    }
  }

  function clearAll() {
    clearTimeout(dec_timeout);
    clearInterval(dec_interval);
    clearTimeout(inc_timeout);
    clearInterval(inc_interval);
  }
});

_globals.$DOCUMENT.on('keydown', '.js-spinner input', function (e) {
  var $input = $(this);

  if (e.keyCode == 46 || e.keyCode == 8 || e.keyCode == 9 || e.keyCode == 27 || e.keyCode == 65 && e.ctrlKey === true || e.keyCode >= 35 && e.keyCode <= 39) {
    return;
  } else {
    if ((e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }
  }
});

_globals.$DOCUMENT.on('keyup paste', '.js-spinner input', function (e) {
  var $input = $(this);
  var may_be_zero = $input.data('zero') ? $input.data('zero') : false;

  if ($input.val() == 0 || $input.val() == '') {
    if (!may_be_zero) {
      $input.val(1);
    } else {
      $input.val(0);
    }
  }
});

},{"./globals.js":6}],18:[function(require,module,exports){
'use strict';

var _helpers = require('./helpers.js');

$(function () {

  $('.js-spoiler').each(function () {
    var $this = $(this);
    $(window).on('spoilersInit resize', function () {
      var isExpanded = $this.hasClass('is-expanded');
      var isInsideHiddenTab = $this.closest('.tabs-panel:not(.is-active)').length;
      var isInsideCollapsedSpoiler = $this.closest('.js-spoiler:not(.is-expanded)').length;
      var isInsideFilter = $this.closest('#filter-aside').length;
      var hasCollapsedLists = $this.find('.js-collapsible-list:not(.is-expanded)').length;
      var heightCalcNeeded = !$this.hasClass('js-spoiler-no-height-calculation');
      var $el = heightCalcNeeded ? $this.find('.js-spoiler-body').first() : null;
      var el = heightCalcNeeded ? $el[0] : null;
      if (!el) return;
      $('.js-spoiler-body').each(function () {
        $(this)[0].style.setProperty('transition', 'none');
        $(this).find('ul').addClass('is-expanded-for-calc');
      });
      if (!isExpanded) $this.addClass('is-expanded-for-calc');
      if (isInsideCollapsedSpoiler) $this.closest('.js-spoiler').addClass('is-expanded');
      if (isInsideHiddenTab) $this.closest('.tabs-panel').addClass('is-active');
      if (isInsideFilter) $this.closest('.catalogue-page__aside')[0].style.setProperty('display', 'block');
      if (hasCollapsedLists) $this.find('.js-collapsible-list').addClass('is-expanded');
      if (heightCalcNeeded) {
        el.style.setProperty('--max-height', '');
        el.style.setProperty('--max-height', $el.outerHeight() + 'px');
      }
      if (!isExpanded) $this.removeClass('is-expanded-for-calc');
      if (isInsideCollapsedSpoiler) $this.closest('.js-spoiler').removeClass('is-expanded');
      if (isInsideHiddenTab) $this.closest('.tabs-panel').removeClass('is-active');
      if (isInsideFilter) $this.closest('.catalogue-page__aside')[0].style.setProperty('display', '');
      if (hasCollapsedLists) $this.find('.js-collapsible-list').removeClass('is-expanded');
      $('.js-spoiler-body').each(function () {
        $(this)[0].style.setProperty('transition', '');
        $(this).find('ul').removeClass('is-expanded-for-calc');
      });
    });

    if ($this.hasClass('js-save-spoiler-state')) {
      if (localStorage.getItem('SPOILER_STATE_' + $this.attr('id')) == null) {
        localStorage.setItem('SPOILER_STATE_' + $this.attr('id'), $this.hasClass('is-expanded'));
      }

      if (localStorage.getItem('SPOILER_STATE_' + $this.attr('id')) == 'true') {
        $this.addClass('is-expanded');
      } else {
        $this.removeClass('is-expanded');
      }
    }
  });
  setTimeout(function () {
    $(window).trigger('spoilersInit');
  }, 0);

  $(document).on('click', '.js-toggle-spoiler', function () {
    var $this = $(this);
    var $spoiler = $this.closest('.js-spoiler');
    var scrollAfter = $this.hasClass('js-scroll-to-closed-spoiler');
    var saveState = $spoiler.hasClass('js-save-spoiler-state');
    $spoiler.toggleClass('is-expanded');
    if (scrollAfter && !$spoiler.hasClass('is-expanded')) {
      $(window).scrollTo($spoiler, { duration: 200, offset: -180 });
    }
    setTimeout(function () {
      $(window).trigger('spoilerToggled');
    }, 250);
    if (saveState) {
      localStorage.setItem('SPOILER_STATE_' + $spoiler.attr('id'), $spoiler.hasClass('is-expanded'));
    }
  });

  $('.js-toggle-mobile-spoiler').click(function (e) {
    if (!(0, _helpers.IS_LANDSCAPE_MOBILE_WIDTH)()) return;
    e.preventDefault();

    if ($(this).closest('.js-menu')) {
      e.preventDefault();
    }

    $(this).closest('.js-spoiler').toggleClass('is-expanded');
    setTimeout(function () {
      $(window).trigger('spoilerToggled');
    }, 250);
  });

  $(document).on('click', '.js-toggle-filter-list', function (e) {
    e.preventDefault();
    var $list = $(this).closest('.js-collapsible-list');

    $list.toggleClass('is-expanded');

    // if ($list.hasClass('js-save-spoiler-state')) {
    //   localStorage.setItem('SPOILER_STATE_' + $spoiler.attr('id'), $spoiler.hasClass('is-expanded'));
    // }
  });

  $('.js-payment-mobile-spoiler').on('click', function () {
    $(this).next('.js-spoiler-body').toggleClass('is-expanded');
    $(this).toggleClass('is-expanded');
  });
});

$(function () {
  if (!$('.js-show-more-catering')) return;

  $('.js-show-more').each(function (index, element) {
    var $this = $(element);
    var id = $this.attr('id');
    var $container = $this.closest('.section').find('.js-show-more-catering');
    var showDefault = $container.data('show-default');
    var addCount = $container.data('add');

    localStorage.setItem('defaultCount_for_' + id, +showDefault); // Сколько показывается по дефолту
    localStorage.setItem('addCount_for_' + id, +addCount); // По столько штук добавляется
  });

  $('.js-show-more').on('click', function (e) {
    e.preventDefault();

    var $this = $(this);
    var id = $this.attr('id');
    var $container = $this.closest('.section').find('.js-show-more-catering');
    var $childrens = $container.children('.card-group__item, .review-group__item');
    var $defaultCount = +localStorage.getItem('defaultCount_for_' + id);
    var $addCount = +localStorage.getItem('addCount_for_' + id);

    for (var j = 0; j <= $addCount; j++) {
      $($childrens[$defaultCount - 1 + j]).addClass('is-shown');
    }

    localStorage.setItem('defaultCount_for_' + id, $defaultCount + $addCount);

    if ($childrens.length <= +localStorage.getItem('defaultCount_for_' + id)) {
      $this.hide();
      $container.addClass('all-shown');
    }
  });
});

},{"./helpers.js":7}],19:[function(require,module,exports){
'use strict';

$(document).on('click', '.js-tabs .tabs-nav a', function (e) {
  e.preventDefault();

  var $this = $(this);
  var $panel = $($this.attr('href'));

  if ($this.closest('li').hasClass('is-active')) return;

  $this.closest('li').addClass('is-active').siblings().removeClass('is-active');
  $panel.closest('.tabs').find('.tabs-panel').removeClass('is-active').hide();
  $panel.fadeIn(function () {
    $panel.addClass('is-active');
  });
});

// $(function() {
//   $('.js-tabs').each(function() {
//     $(this).find('.tabs-nav li:first a').click();
//   });
// });

},{}],20:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

var _helpers = require('./helpers.js');

$(function () {
  _globals.$DOCUMENT.on('click', '.js-tooltip-anchor', function (e) {
    e.preventDefault();
    e.stopPropagation();

    if ((0, _helpers.IS_LANDSCAPE_MOBILE_WIDTH)()) {
      $(this).closest('.js-tooltip').toggleClass('is-active');
    }
  });

  if ((0, _helpers.IS_LANDSCAPE_MOBILE_WIDTH)()) {
    _globals.$DOCUMENT.on('click', '.js-tooltip-content', function (e) {
      e.stopPropagation();
    });
  }

  if (!(0, _helpers.IS_LANDSCAPE_MOBILE_WIDTH)()) {
    tippy.delegate('body', {
      target: '.js-tooltip-anchor',
      appendTo: function appendTo() {
        return document.body;
      },
      trigger: 'click',
      content: function content(reference) {
        return $(reference).closest('.js-tooltip').find('.js-tooltip-content').clone().html();
      },
      allowHTML: true,
      interactive: true,
      touch: true,
      onTrigger: function onTrigger(instance, event) {
        event.stopPropagation();
        event.preventDefault();
      },
      onUntrigger: function onUntrigger(instance, event) {
        event.stopPropagation();
        event.preventDefault();
      },

      popperOptions: {
        strategy: 'fixed',
        modifiers: [{
          name: 'flip',
          options: {
            fallbackPlacements: ['bottom', 'right']
          }
        }, {
          name: 'preventOverflow',
          options: {
            altAxis: true,
            tether: false
          }
        }]
      }
    });
  }
});

},{"./globals.js":6,"./helpers.js":7}],21:[function(require,module,exports){
'use strict';

var _PriceComponent = require('./components/PriceComponent.js');

var _PriceComponent2 = _interopRequireDefault(_PriceComponent);

var _OffersListRadioComponent = require('./components/OffersListRadioComponent.js');

var _OffersListRadioComponent2 = _interopRequireDefault(_OffersListRadioComponent);

var _OffersListSelectComponent = require('./components/OffersListSelectComponent.js');

var _OffersListSelectComponent2 = _interopRequireDefault(_OffersListSelectComponent);

var _OffersParamComponent = require('./components/OffersParamComponent.js');

var _OffersParamComponent2 = _interopRequireDefault(_OffersParamComponent);

var _OffersImageComponent = require('./components/OffersImageComponent.js');

var _OffersImageComponent2 = _interopRequireDefault(_OffersImageComponent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

window.initCardApp = function () {
  if (!$('#js-card-app').length) return;

  var cardPriceApp = new Vue({
    el: '#js-card-app',
    components: {
      'price-component': _PriceComponent2.default,
      'offers-list-radio': _OffersListRadioComponent2.default,
      'offers-list-select': _OffersListSelectComponent2.default,
      'offers-param': _OffersParamComponent2.default,
      'offers-image': _OffersImageComponent2.default
    },
    data: function data() {
      return {
        offerInner: {},
        targetButton: ''
      };
    },
    methods: {
      updateBasketButton: function updateBasketButton() {
        //Обновляем данные на кнопке купить
        var data = $(this.targetButton).data();
        $.extend(data, this.offerInner.basketData);

        if (this.offerInner.basketData.inCollection) {
          $(this.targetButton).addClass('active').html('Товар в корзине');
        } else {
          $(this.targetButton).removeClass('active').html('Добавить в корзину');
        }
      },
      clickBasketButton: function clickBasketButton() {
        //Обновляем данные по нажатию кнопки купить
        this.$refs.foo.setInCollection(this.offerInner.id, true);
      },
      initSelectric: function initSelectric() {
        var self = this;
        $(this.$el).find('select.no-default-selectric-core-component').each(function () {
          $(this).selectric({
            disableOnMobile: false,
            nativeOnMobile: true,
            inheritOriginalWidth: $(this).hasClass('default-width')
          }).selectric('refresh');
        }).on('selectric-change', function () {
          $(self.$el).find('select')[0].dispatchEvent(new Event('change'));
        });
      }
    },
    computed: {
      offer: {
        get: function get() {
          return this.offerInner;
        },
        set: function set(offerData) {
          this.offerInner = offerData;
          this.$emit('changeOffer', this.offerInner);
          this.updateBasketButton();
        }
      }
    },
    mounted: function mounted() {
      this.$nextTick(function () {
        this.offer = JSON.parse(this.$el.getAttribute('data-default-offer'));
        this.targetButton = this.$el.getAttribute('data-target-button');
        this.$emit('setDefaultOffer');
        this.initSelectric();
      });
    }
  });
}; /**
    * Подключение в common.js
    *   import './modules/card-choose-offer.js';
    *
    * Пример подключения в шаблоне
    * <div id="js-card-app" class="product-card__controls-column" data-default-offer='<?=$model->getBasketObjectJson()?>' data-target-button=".js-cart-basket-button">
   
    * <offers-list-component :offers-list='<?=json_encode($offers)?>' inline-template>
    *  <div>
    *    <label class="item js-radio" v-bind:class="{'is-checked': isActive(offer.id)}" v-bind:data-size="offer.text"
    *           v-for="offer in offersList" @click="$root.offer = offer">{{offer.text}}</label>
    *  </div>
    * </offers-list-component>
    *
    * <price-component :price="this.offer.price" :old-price="this.offer.oldPrice" inline-template>
    * <div class="product-card__price-group">
    *   <div class="product-card__old-price" v-if="oldPrice">
    *     <span v-html="oldPriceFormatted"></span>
    *     <div class="product-card__discount" v-html="economyPercentFormatted"></div>
    *   </div>
    *   <div class="product-card__price" v-html="priceFormatted"><?php echo PriceHelper::price($model->getPrice(), ' <span class="currency"><span>₽</span></span>');?></div>
    * </div>
    * </price-component>
    *
    * </div>
    *
    */


window.initCardApp();

},{"./components/OffersImageComponent.js":22,"./components/OffersListRadioComponent.js":23,"./components/OffersListSelectComponent.js":24,"./components/OffersParamComponent.js":25,"./components/PriceComponent.js":26}],22:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var OffersImage = {
  computed: {
    offerImagesList: function offerImagesList() {
      if (!this.$root.offer.images) return null;

      return this.$root.offer.images.length ? this.$root.offer.images : null;
    },
    hasGallery: function hasGallery() {
      if (this.offerImagesList && this.offerImagesList.length > 1) return true;
      return false;
    }
  },
  watch: {
    offerImagesList: function offerImagesList(val) {
      setTimeout(function () {
        $(window).trigger('offerGalleryInit');
      }, 200);
    }
  },
  methods: {
    initImage: function initImage(offer) {
      //console.log('Нужно обновить картинку!');
    },
    getMainImage: function getMainImage() {
      var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'big';

      if (!this.$root.offer.hasOwnProperty('images')) return null;

      if (!Object.keys(this.$root.offer.images).length) return null;

      if (!this.$root.offer.images.hasOwnProperty('main')) return null;

      if (!Object.keys(this.$root.offer.images.main).length) return null;

      return this.$root.offer.images.main[0][type]['src'];
    },
    getMainImageWidth: function getMainImageWidth() {
      var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'big';

      if (!this.getMainImage(type)) return null;

      return this.$root.offer.images.main[0][type]['width'];
    },
    getMainImageHeight: function getMainImageHeight() {
      var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'big';

      if (!this.getMainImage(type)) return null;

      return this.$root.offer.images.main[0][type]['heigth'];
    },
    zoomOnHover: function zoomOnHover(e) {
      // зум картинки при наведении
      if (window.innerWidth <= 1024) return false;
      var zoomer = e.currentTarget;

      if (getComputedStyle(zoomer).backgroundImage == 'none') {
        var zoomerImgLink = zoomer.querySelector('source[media="(min-width: 481px)"]').getAttribute("srcset");

        zoomer.style.backgroundImage = 'url(\'' + zoomerImgLink + '\')';
      }

      var offsetX = void 0,
          offsetY = void 0;
      e.offsetX ? offsetX = e.offsetX : offsetX = e.touches[0].pageX;
      e.offsetY ? offsetY = e.offsetY : offsetX = e.touches[0].pageX;
      var x = offsetX / zoomer.offsetWidth * 100;
      var y = offsetY / zoomer.offsetHeight * 100;
      zoomer.style.backgroundPosition = x + '% ' + y + '%';
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.$root.$on('changeOffer', function (offer) {
      _this.initImage(offer);
    });
  }
};

exports.default = OffersImage;

},{}],23:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Пирмер подключения в шаблоне:
 * <?php $offers = $model->getOffersWithParameter(false) ?>
 * <offers-list-component :offers-list='<?=json_encode($offers)?>' inline-template>
 *  <div>
 *    <label class="item js-radio" v-bind:class="getClass(offer)" v-bind:data-size="offer.text"
 *           v-for="offer in offersList" @click="click(offer)">{{offer.text}}</label>
 *  </div>
 * </offers-list-component>
 */

/**
 * @type {{props: {offersList: {type: ArrayConstructor}}, methods: {isActive(*): *}}}
 */
var OffersListRadio = {
  props: {
    offersList: {
      type: Array
    },
    selectedClass: {
      type: String,
      default: 'is-checked'
    },
    disabledClass: {
      type: String,
      default: 'disabled'
    }
  },
  methods: {
    isActive: function isActive(id) {
      return this.$root.offer.id == id;
    },
    click: function click(offer) {
      if (offer.disabled) return;

      this.$root.offer = offer;
    },
    getClass: function getClass(offer) {
      var classArray = [];

      if (this.isActive(offer.id)) classArray.push(this.selectedClass);

      if (offer.disabled) classArray.push(this.disabledClass);

      return classArray.join(' ');
    }
  }
};

exports.default = OffersListRadio;

},{}],24:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @type {{props: {offersList: {type: ArrayConstructor}}}}
 */
var OffersListSelect = {
  data: function data() {
    return {
      selectedId: null,
      offersListdata: this.offersList
    };
  },
  props: {
    offersList: {
      type: Array
    }
  },
  methods: {
    setOfferById: function setOfferById(id) {
      for (var i in this.offersListdata) {
        if (this.offersListdata[i].id === id) {
          this.$root.offer = this.offersListdata[i];
          return;
        }
      }
    },
    initSelectric: function initSelectric() {
      var self = this;
      $(this.$el).find('select.no-default-selectric').each(function () {
        $(this).selectric({
          disableOnMobile: false,
          nativeOnMobile: true,
          inheritOriginalWidth: $(this).hasClass('default-width')
        }).selectric('refresh');
      }).on('selectric-change', function () {
        $(self.$el).find('select')[0].dispatchEvent(new Event('change'));
      });
    },
    setInCollection: function setInCollection(id, flag) {
      for (var i in this.offersListdata) {
        if (this.offersListdata[i].id === id) {
          this.offersListdata[i].basketData.inCollection = flag;
          //console.log(this.offersListdata);
          return;
        }
      }
    }
  },
  watch: {
    selectedId: function selectedId(offerId) {
      this.setOfferById(offerId);
    }
  },

  mounted: function mounted() {
    var _this = this;

    // Устанавливаем значение по умолчанию
    this.$root.$on('setDefaultOffer', function () {
      _this.selectedId = _this.$root.offer.id;
      _this.$nextTick(function () {
        this.initSelectric();
      });
    });
  }
};

exports.default = OffersListSelect;

},{}],25:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var OffersParam = {
  data: function data() {
    return {
      param: {}
    };
  },

  methods: {
    initParams: function initParams(offer) {
      var outParam = {};
      for (var i in offer.parameters) {
        var param = offer.parameters[i];
        if (param.keys) {
          for (var key in param.keys) {
            outParam[key] = {
              id: param.id,
              name: param.name,
              value: param.value
            };
          }
        } else {
          outParam[param.id] = {
            id: param.id,
            name: param.name,
            value: param.value
          };
        }
      }

      this.param = outParam;
      //this.$root.$data.param = outParam;
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.$root.$on('changeOffer', function (offer) {
      _this.initParams(offer);
    });
  },

  template: '<div></div>'
};

exports.default = OffersParam;

},{}],26:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Пример подключения в шаблоне
 * <price-component :price="this.offer.price" :old-price="this.offer.oldPrice" inline-template>
 * <div class="product-card__price-group">
 *   <div class="product-card__old-price" v-if="oldPrice">
 *     <span v-html="oldPriceFormatted"></span>
 *     <div class="product-card__discount" v-html="economyPercentFormatted"></div>
 *   </div>
 *   <div class="product-card__price" v-html="priceFormatted"><?php echo PriceHelper::price($model->getPrice(), ' <span class="currency"><span>₽</span></span>');?></div>
 * </div>
 * </price-component>
 */

/**
 * @type {{props: {price: {type: NumberConstructor}, oldPrice: {type: NumberConstructor, default: number}, currencySuffix: {type: StringConstructor, default: string}, emptyPriceText: {type: StringConstructor, default: string}}, computed: {economyPercent(): (number|*), priceFormatted(): *, oldPriceFormatted(): *, economyPercentFormatted(): string}}}
 */
var Price = {
  props: {
    price: {
      type: Number
    },
    oldPrice: {
      type: Number,
      default: 0
    },
    currencySuffix: {
      type: String,
      default: ' <span class="currency"><span>₽</span></span>'
    },
    emptyPriceText: {
      type: String,
      default: ' <span class="call">Спец. цена, звоните!</span>'
    }
  },
  computed: {
    economyPercent: function economyPercent() {
      if (!this.oldPrice) return 0;
      var percent = (this.price - this.oldPrice) * 100 / this.oldPrice;
      return Math.ceil(percent);
    },
    priceFormatted: function priceFormatted() {
      if (this.price > 0) return this.price.toLocaleString() + this.currencySuffix;

      return this.emptyPriceText;
    },
    oldPriceFormatted: function oldPriceFormatted() {
      if (this.oldPrice > 0) return this.oldPrice.toLocaleString() + this.currencySuffix;

      return this.emptyPriceText;
    },
    economyPercentFormatted: function economyPercentFormatted() {
      return this.economyPercent + '%';
    }
  }
};

exports.default = Price;

},{}],27:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var AddressList = {
  props: {
    defaultAddressList: Array,
    defaultSelectedIndex: Number
  },
  data: function data() {
    return {
      addressList: {},
      emptyData: {
        name: '',
        address: ''
      },
      selectedAddressItem: {},
      address: '',
      selectedIndex: null
    };
  },
  methods: {
    setDefault: function setDefault() {
      this.addressList = this.defaultAddressList;
      this.resetSelect();
    },
    isSetAddress: function isSetAddress() {
      return this.selectedAddressItem.name != '';
    },
    isSelected: function isSelected(item) {
      return this.selectedAddressItem.name == item.name;
    },
    selectAddress: function selectAddress(addressItem) {
      this.selectedAddressItem = addressItem;
      this.address = this.selectedAddressItem.address;
    },
    resetSelect: function resetSelect() {
      this.selectedAddressItem = this.emptyData;
      this.selectedIndex = null;

      //Обновляем стилизатор
      $('.js-radio input').trigger('initRadio');
    },
    getNewIndex: function getNewIndex() {
      return $('.form__field-group').length;
    }
  },
  watch: {
    selectedIndex: function selectedIndex(index) {
      if (index == null || index == undefined) return;

      this.selectAddress(this.addressList[index]);
    },
    address: function address(_address) {

      if (this.selectedAddressItem.address != _address) this.resetSelect();
    }
  },
  mounted: function mounted() {
    var vue = this;
    argilla.event.attachEventHandler('dadataSuggestionAddress', function (data) {
      var element = data[1]; // если будет нексолько аддресов, то придется придумать фильтрацию по элементам
      vue.address = data[0].value;
    });
    this.$nextTick(function () {
      this.setDefault();
    });
  }
};

exports.default = AddressList;

},{}],28:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var Bonuses = {
  props: {
    defaultBalance: Number,
    defaultReservedScore: Number,
    reserveUrl: String
  },
  data: function data() {
    return {
      balance: 0,
      reservedScore: '',
      spend: ''
    };
  },
  methods: {
    setDefault: function setDefault() {
      this.balance = this.defaultBalance;
      this.reservedScore = this.defaultReservedScore;
    },
    clickSpend: function clickSpend() {
      //console.log(this.$root.$children[1].$data.orderSum);
      this._reserve();
    },
    clickCancel: function clickCancel() {
      this._cancel();
    },
    isReserved: function isReserved() {
      return this.reservedScore > 0;
    },
    _reserve: function _reserve() {
      this._sentToServer();
    },
    _error: function _error(message) {
      dialog('alert', message, { labels: { ok: 'Ok' } });
    },
    _success: function _success() {
      this.reservedScore = Number(this.spend);
    },
    _cancel: function _cancel() {
      this.reservedScore = 0;
      this.spend = 0;
      this._sentToServer();
    },
    _sentToServer: function _sentToServer() {
      var vue = this;
      $.post(this.reserveUrl, { score: this.spend }, function (result) {
        if (result.error) vue._error(result.error);else if (result.success) vue._success();
        vue._refreshBasket();
      });
    },
    _refreshBasket: function _refreshBasket() {
      $.fn.collection('basket').send({});
    }
  },
  watch: {
    'reservedScore': function reservedScore(value) {
      if (value > 0) {
        this.spend = value;
        this.$root.bonusesActivated = true;
      } else {
        this.spend = '';
        this.$root.bonusesActivated = false;
      }
    }
  },
  mounted: function mounted() {
    var vue = this;
    this.$nextTick(function () {
      this.setDefault();
    });
  }
};

Vue.directive('restrict', {
  bind: function bind(el, binding) {
    el.addEventListener('keydown', function (e) {
      // delete, backpsace, tab, escape, enter,
      var special = [46, 8, 9, 27, 13];
      if (binding.modifiers['decimal']) {
        // decimal(numpad), period
        special.push(110, 190);
      }
      // special from above
      if (special.indexOf(e.keyCode) !== -1 ||
      // Ctrl+A
      e.keyCode === 65 && e.ctrlKey === true ||
      // Ctrl+C
      e.keyCode === 67 && e.ctrlKey === true ||
      // Ctrl+X
      e.keyCode === 88 && e.ctrlKey === true ||
      // home, end, left, right
      e.keyCode >= 35 && e.keyCode <= 39) {
        return; // allow
      }
      if (binding.modifiers['alpha'] &&
      // a-z/A-Z
      e.keyCode >= 65 && e.keyCode <= 90) {
        return; // allow
      }
      if (binding.modifiers['number'] && (
      // number keys without shift
      !e.shiftKey && e.keyCode >= 48 && e.keyCode <= 57 ||
      // numpad number keys
      e.keyCode >= 96 && e.keyCode <= 105)) {
        return; // allow
      }
      // otherwise stop the keystroke
      e.preventDefault(); // prevent
    });
  }
});

exports.default = Bonuses;

},{}],29:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var CartSharing = {
  data: function data() {
    return {
      copied: false,
      url: null
    };
  },

  props: {
    defaultUrl: String
  },
  methods: {
    selectInputContent: function selectInputContent(event) {
      $(event.target).select();
    },
    copyToClipboard: function copyToClipboard() {
      this._copyTextToClipboard(this.url);
    },
    _successHandler: function _successHandler() {
      this.copied = true;
    },
    _copyTextToClipboard: function _copyTextToClipboard(text) {
      var vm = this;
      if (!navigator.clipboard) {
        this._fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text).then(function () {
        vm._successHandler();
      }, function (err) {});
    },
    _fallbackCopyTextToClipboard: function _fallbackCopyTextToClipboard(text) {
      var vm = this;
      var textArea = document.createElement('textarea');
      textArea.value = text;
      // Avoid scrolling to bottom
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.position = 'fixed';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        var successful = document.execCommand('copy');
        if (successful) {
          vm._successHandler();
        }
      } catch (err) {}
      document.body.removeChild(textArea);
    }
  },
  created: function created() {
    this.url = this.defaultUrl;
  },
  mounted: function mounted() {
    var _this = this;

    $(document).on('afterReplaceWith', function () {
      _this.url = $('.js-cart-share-url').data('url');
    });
    $(document).on('click', '#basket-share-linck', function () {
      var vm = _this;
      $.post('/basket/get-share/', { 'refreshBasketShare': 1 }, function (resp, status, xhr) {
        vm.url = resp;
      });
    });
  }
};

exports.default = CartSharing;

},{}],30:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var DeliveryDate = {
  props: {
    defaultDeliveryDateList: Object,
    timeStamp: Number
  },
  data: function data() {
    return {
      date: '',
      time: '',
      currentDate: {},
      deliveryDateList: {},
      deliveryDate: { // Все в минутах
        date: {},
        timeStart: 0,
        timeEnd: 0,
        startEndStep: 0,
        deliveryInterval: 0,
        transferToNextDay: '',
        notice: '',
        disabled: false,
        minPrice: null
      },
      timer: null
    };
  },
  methods: {
    setDefault: function setDefault() {
      this.deliveryDateList = this.defaultDeliveryDateList;
      this.currentDate = new Date(this.timeStamp);
      this.onTimer();
    },
    onTimer: function onTimer() {
      clearInterval(this.timer);
      var vue = this;
      this.timer = setInterval(function () {
        return vue.currentDate = moment(vue.currentDate).add(1, 'm').toDate();
      }, 1000 * 3600); // Обновляем время 1 раз в минуту
    },
    isShowDeliveryInterval: function isShowDeliveryInterval() {
      if (this.deliveryDate.disabled) {
        this.time = '';
        return false;
      }
      return this.date !== '';
    },
    getNotice: function getNotice() {
      return this.deliveryDate.notice;
    },
    getIntervalDeliveryList: function getIntervalDeliveryList() {
      return this._getIntervalDeliveryListByDate(this.deliveryDate);
    },
    _setDeliveryDate: function _setDeliveryDate(date) {
      this.deliveryDate = this._findDeliveryDate(date);
      this.$root.$emit('setDeliveryDate', this.deliveryDate);
    },
    _getIntervalDeliveryListByDate: function _getIntervalDeliveryListByDate(deliveryDate) {
      var start = deliveryDate.timeStart;
      var end = deliveryDate.timeEnd;
      var intervalList = [];

      // Защита от зависания
      if (start >= end) return;

      var current = start;
      while (1) {
        var next = current + deliveryDate.deliveryInterval;

        if (!this._isFullInterval(current, next, deliveryDate)) {
          break;
        }

        intervalList.push({
          start: this.minutesToTimeString(current),
          end: this.minutesToTimeString(next)
        });

        if (current >= end) break;

        current += deliveryDate.startEndStep;
      }
      return intervalList;
    },


    // Проверяем полноту интервала и выход за границы конца доставки
    _isFullInterval: function _isFullInterval(current, next, deliveryDate) {
      if (next > deliveryDate.timeEnd) return false;

      if (next - current > deliveryDate.deliveryInterval) return false;

      return true;
    },
    _findDeliveryDate: function _findDeliveryDate(date) {
      if (this.deliveryDateList[date] !== undefined) return this.deliveryDateList[date];

      if (this.deliveryDateList.current.date === date) {
        return this.deliveryDateList.current;
      } else {
        return this.deliveryDateList.next;
      }
    },
    minutesToTimeString: function minutesToTimeString(minutes) {
      var hour = Math.floor(minutes / 60);
      var min = Math.floor(minutes % 60);
      var delimiter = min < 10 ? ":0" : ":";
      return hour + delimiter + min;
    },
    dateToYMD: function dateToYMD(date) {
      var d = date.getDate();
      var m = date.getMonth() + 1;
      var y = date.getFullYear();
      return '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
    }
  },
  watch: {
    date: function date(_date) {
      this._setDeliveryDate(_date);
    },
    time: function time(_time) {
      // Можно отследить момент завершения приема заказов с доставкой на сегодня и проинформировать пользователя
    }
  },
  computed: {
    formattedDate: function formattedDate() {
      return this.date != '' ? $.datepicker.formatDate('dd.mm.yy', new Date(this.date)) : '';
    }
  },
  mounted: function mounted() {
    var vue = this;
    this.$nextTick(function () {
      this.setDefault();
      $('.date-input').each(function () {
        var $input = $(this);
        $input.datepicker({
          minDate: $input.attr('min') ? new Date($input.attr('min')) : 0,
          onSelect: function onSelect(dateText, inst) {
            vue.date = vue.dateToYMD(new Date(inst.selectedYear, inst.selectedMonth, inst.selectedDay));
            $input.trigger('change');
          },
          beforeShowDay: function beforeShowDay(date) {
            var day = vue.dateToYMD(date);
            if (vue.deliveryDateList['current']['date'] == day && vue.deliveryDateList['current']['disabled'] == true) {
              return [true, "ui-state-disabled ui-datepicker-today", ""];
            } else if (vue.deliveryDateList[day] != undefined) {
              if (vue.deliveryDateList[day]['disabled'] == true) return [false, "", ""];
            }
            return [true, "", ""];
          }
        });
      });
    });
  }
};

exports.default = DeliveryDate;

},{}],31:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _OrganizationListComponent = require("./OrganizationListComponent.js");

var _OrganizationListComponent2 = _interopRequireDefault(_OrganizationListComponent);

var _AddressListComponent = require("./AddressListComponent.js");

var _AddressListComponent2 = _interopRequireDefault(_AddressListComponent);

var _DeliveryDateComponents = require("./DeliveryDateComponents.js");

var _DeliveryDateComponents2 = _interopRequireDefault(_DeliveryDateComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//import OrderMinPrice from "./OrderMinPrice.js";

var Form = {
  props: {
    defaultType: String
  },
  components: {
    'organization-list': _OrganizationListComponent2.default,
    'address-list': _AddressListComponent2.default,
    'delivery-date': _DeliveryDateComponents2.default
    //    'min-price': OrderMinPrice
  },
  data: function data() {
    return {
      typeLegal: 'individual',
      paymentType: null
    };
  },
  methods: {
    setDefault: function setDefault() {
      if (this.defaultType == undefined || this.defaultType == null) return;

      this.typeLegal = this.defaultType;

      //Обновляем стилизатор
      $('.js-radio input').trigger('initRadio');
    }
  },
  computed: {
    isIndividual: function isIndividual() {
      return this.typeLegal == 'individual';
    },
    isLegal: function isLegal() {
      return this.typeLegal == 'organization';
    },
    isCachelessPayment: function isCachelessPayment() {
      return this.paymentType == 2;
    }
  },
  mounted: function mounted() {
    this.setDefault();
    this.$nextTick(function () {
      $('.js-radio input').trigger('initRadio');
    });
  }
};

exports.default = Form;

},{"./AddressListComponent.js":27,"./DeliveryDateComponents.js":30,"./OrganizationListComponent.js":33}],32:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var OrderMinPrice = {
  props: {
    defaultMinPrice: Number,
    defaultOrderSum: Number,
    priceSuffix: String
  },
  data: function data() {
    return {
      minPrice: 0,
      orderSum: 0
    };
  },
  methods: {
    setDefault: function setDefault() {
      this.minPrice = this.defaultMinPrice;
      this.orderSum = this.defaultOrderSum;
    },
    needForOrder: function needForOrder() {
      var needForOrder = this.minPrice - this.orderSum;
      return needForOrder < 0 ? 0 : needForOrder;
    },
    minPriceText: function minPriceText() {
      return new Intl.NumberFormat('ru-RU').format(this.minPrice) + this.priceSuffix;
    },
    needForOrderText: function needForOrderText() {
      return new Intl.NumberFormat('ru-RU').format(this.needForOrder()) + this.priceSuffix;
    },
    _setMinPrice: function _setMinPrice(minPrice) {
      this.minPrice = minPrice;
    }
  },
  watch: {},
  mounted: function mounted() {
    var vue = this;
    this.$nextTick(function () {
      this.setDefault();
      this.$root.$on('setDeliveryDate', function (deliveryDate) {
        vue._setMinPrice(deliveryDate.minPrice);
      });
    });
  }
};

exports.default = OrderMinPrice;

},{}],33:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var OrganizationsList = {
  props: {
    organizationListDefault: Array,
    defaultSelectedIndex: Number
  },
  data: function data() {
    return {
      emptyOrganization: {
        name: '',
        zipCode: '',
        city: '',
        address: '',
        inn: '',
        kpp: '',
        bank: '',
        bankCity: '',
        bik: '',
        account: '',
        corAccount: ''
      },
      organization: {},
      organizationList: [],
      selectedIndex: null
    };
  },
  methods: {
    concatOrganizationData: function concatOrganizationData(organization) {
      var data = [];
      var keys = Object.keys(organization);

      keys.map(function (v) {
        if (v == 'name') return;

        var item = organization[v];

        if (item != '' && item != null) data.push(item);

        return organization[v];
      });

      return data.join(', ');
    },
    setOrientationFromDadata: function setOrientationFromDadata(datata) {
      //https://dadata.ru/api/suggest/party/
      //this.organization = name = data.name;
      this.organization = {
        name: datata.value,
        inn: datata.data.inn,
        kpp: datata.data.kpp,
        city: datata.data.address.data.city_with_type,
        address: datata.data.address.value.replace(datata.data.address.data.city_with_type + ',', ''),
        zipCode: datata.data.address.data.postal_code
      };
    },
    setDefault: function setDefault() {
      if (this.organizationListDefault == undefined || this.organizationListDefault.length == 0) return;

      this.organizationList = this.organizationListDefault;
      this.selectedIndex = this.defaultSelectedIndex == null ? 0 : this.defaultSelectedIndex;
      this.organization = this.organizationList[this.selectedIndex];
    },
    getSelectedIndex: function getSelectedIndex() {
      if (!this.isSelectedOrganization()) return null;

      return this.selectedIndex;
    },
    isSelectedOrganization: function isSelectedOrganization(organization) {
      return organization.name == this.organization.name;
    },
    addOrganizationFromForm: function addOrganizationFromForm(selector) {

      var organization = Object.assign({}, this.emptyOrganization);
      $(selector).each(function () {
        var attr = $(this).attr('name');
        var value = $(this).val();
        organization[attr] = value;
      });

      /**
       *  Добавляем новый элемент в массив с поддержкой реактивности
       *  @link https://ru.vuejs.org/v2/guide/reactivity.html
       */
      Vue.set(this.organizationList, this.organizationList.length, organization);
      this.selectedIndex = this.organizationList.length - 1;
    },
    isSetOrganization: function isSetOrganization() {
      if (this.selectedIndex == null || this.selectedIndex == undefined) return false;

      return true;
    },
    resetOrganization: function resetOrganization() {
      this.selectedIndex = null;
      this.organization = this.emptyOrganization;
    }
  },
  watch: {
    selectedIndex: function selectedIndex(index) {
      if (index != null) this.organization = this.organizationList[index];
    }
  },
  mounted: function mounted() {
    var vue = this;
    argilla.event.attachEventHandler('dadataSuggestionOrganization', function (organization) {
      vue.setOrientationFromDadata(organization);
    });
    this.resetOrganization();
    this.$nextTick(function () {
      this.setDefault();
    });
  }
};

exports.default = OrganizationsList;

},{}],34:[function(require,module,exports){
"use strict";

var _OrderFormComponent = require("./components/OrderFormComponent.js");

var _OrderFormComponent2 = _interopRequireDefault(_OrderFormComponent);

var _OrderMinPrice = require("./components/OrderMinPrice.js");

var _OrderMinPrice2 = _interopRequireDefault(_OrderMinPrice);

var _BonusesComponent = require("./components/BonusesComponent.js");

var _BonusesComponent2 = _interopRequireDefault(_BonusesComponent);

var _CartSharing = require("./components/CartSharing.js");

var _CartSharing2 = _interopRequireDefault(_CartSharing);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

window.initOrderApp = function () {
  if (!$('#js-order-app').length) return;

  var orderApp = new Vue({
    el: '#js-order-app',
    components: {
      'order-form': _OrderFormComponent2.default,
      'min-price': _OrderMinPrice2.default,
      'bonuses': _BonusesComponent2.default,
      'cart-sharing': _CartSharing2.default
    },
    data: {
      bonusesActivated: false,
      promocodeActivated: false
    },
    mounted: function mounted() {
      this.$nextTick(function () {
        $(window).trigger('initializePhoneMasks');
      });
    }
  });
};

window.initOrderApp();

},{"./components/BonusesComponent.js":28,"./components/CartSharing.js":29,"./components/OrderFormComponent.js":31,"./components/OrderMinPrice.js":32}],35:[function(require,module,exports){
'use strict';

var _OrganizationListComponent = require('../order/components/OrganizationListComponent.js');

var _OrganizationListComponent2 = _interopRequireDefault(_OrganizationListComponent);

var _AddressListComponent = require('../order/components/AddressListComponent.js');

var _AddressListComponent2 = _interopRequireDefault(_AddressListComponent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//import ProfileForm from '../order/components/OrderFormComponent.js';

if ($('#vue-profile-app').length) {

  window.initProfileApp = new Vue({
    el: '#vue-profile-app',
    components: {
      //'profile-form': ProfileForm
      'address-list': _AddressListComponent2.default,
      'organization-list': _OrganizationListComponent2.default
    },
    data: function data() {
      return {
        email: ''
      };
    },
    methods: {
      submit: function submit(data) {
        console.log(data);
      },
      dateToYMD: function dateToYMD(date) {
        var d = date.getDate();
        var m = date.getMonth() + 1;
        var y = date.getFullYear();
        return '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
      }
    },
    mounted: function mounted() {
      var vue = this;
      this.$nextTick(function () {
        $('.date-input').each(function () {
          var $input = $(this);
          $input.datepicker({
            changeMonth: true,
            changeYear: true,
            yearRange: '-100:-14',
            defaultDate: $input.val() == '' ? '-14y' : $input.val(),
            monthNamesShort: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
            beforeShow: function beforeShow(el, obj) {
              var $calendar = obj.dpDiv;
              $calendar.addClass('no-arrows');
            }
          });
        });
      });
    }
  });
}

},{"../order/components/AddressListComponent.js":27,"../order/components/OrganizationListComponent.js":33}],36:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var CodeTimer = {
  props: {
    startInterval: {
      type: Number
    }
  },
  data: function data() {
    return {
      timer: null,
      interval: 0
    };
  },
  methods: {
    setDefault: function setDefault() {
      this.interval = this.startInterval;
      this.onTimer();
    },
    onTimer: function onTimer() {
      clearInterval(this.timer);
      var vue = this;
      this.timer = setInterval(function () {
        return vue.interval >= 1 ? vue.interval-- : 0;
      }, 1000); // Обновляем 1 раз в секунду
    },
    isAllowedRepeatSend: function isAllowedRepeatSend() {
      return this.interval > 0;
    }
  },
  mounted: function mounted() {
    this.$nextTick(function () {
      this.setDefault();
      $('.timer-show-block').show();
    });
  }
};

exports.default = CodeTimer;

},{}],37:[function(require,module,exports){
'use strict';

var _CodeTimerComponent = require('./components/CodeTimerComponent.js');

var _CodeTimerComponent2 = _interopRequireDefault(_CodeTimerComponent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

window.initUserApp = function () {
  var selector = '#js-user-app';

  if (!$(selector).length) return;

  var app = new Vue({
    el: selector,
    components: {
      'code-timer': _CodeTimerComponent2.default
    },
    data: function data() {
      return {};
    },
    mounted: function mounted() {
      this.$nextTick(function () {});
    }
  });
};

window.initUserApp();

},{"./components/CodeTimerComponent.js":36}],38:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _storageDateChecker = require('./shared/storage-date-checker');

var _storageDateChecker2 = _interopRequireDefault(_storageDateChecker);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; } /**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * markup example:
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * <div class="js-floating-teaser"> <!-- should be hidden by css initially -->
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *   <div class="js-close-floating-teaser"></div>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * </div>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * initialization example:
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * const teaser = new ClosableTeaser({
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *   selector: '.js-floating-teaser',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *   closeSelector: '.js-close-floating-teaser',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *   storageKey: 'TEASER_HIDE_DATE',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *   daysToHide: 7,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * });
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                */

var ClosableTeaser = function (_StorageDateChecker) {
  _inherits(ClosableTeaser, _StorageDateChecker);

  function ClosableTeaser(options) {
    _classCallCheck(this, ClosableTeaser);

    var _this = _possibleConstructorReturn(this, (ClosableTeaser.__proto__ || Object.getPrototypeOf(ClosableTeaser)).call(this, {
      storageKey: options.storageKey,
      daysToHide: options.daysToHide
    }));

    _this.$teaser = $(options.selector);
    _this.$close_trigger = $(options.closeSelector);
    _this.init();
    return _this;
  }

  _createClass(ClosableTeaser, [{
    key: 'init',
    value: function init() {
      var _this2 = this;

      if (this.$teaser.length && this.itsTimeToShow()) {
        this.show();
        this.$close_trigger.click(function () {
          _this2.hide();
        });
      }
    }
  }, {
    key: 'show',
    value: function show() {
      this.$teaser.stop(true, true).fadeIn();
    }
  }, {
    key: 'hide',
    value: function hide() {
      this.$teaser.stop(true, true).fadeOut();
      this.setHideDate(new Date().getTime());
    }
  }]);

  return ClosableTeaser;
}(_storageDateChecker2.default);

exports.default = ClosableTeaser;

},{"./shared/storage-date-checker":39}],39:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var StorageDateChecker = function () {
  function StorageDateChecker(options) {
    _classCallCheck(this, StorageDateChecker);

    this.storage_key = options.storageKey;
    this.hide_period = 1000 * 60 * 60 * 24 * options.daysToHide;

    this.hide_date = this.getHideDate();
    this.show_date = this.hide_date * 1 + this.hide_period;
  }

  _createClass(StorageDateChecker, [{
    key: "getHideDate",
    value: function getHideDate() {
      return localStorage.getItem(this.storage_key);
    }
  }, {
    key: "setHideDate",
    value: function setHideDate(date) {
      localStorage.setItem(this.storage_key, date);
    }
  }, {
    key: "itsTimeToShow",
    value: function itsTimeToShow() {
      var current_date = new Date().getTime();

      if (!this.hide_date || current_date > this.show_date) {
        return true;
      } else {
        return false;
      }
    }
  }]);

  return StorageDateChecker;
}();

exports.default = StorageDateChecker;

},{}]},{},[2]);
